import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export const useRouteParams = () => {
  const route = useRouter()
  const routeParams = ref(route.currentRoute.value.params)

  watch(() => route.currentRoute.value.params, newParams => {
    routeParams.value = newParams
  })

  return { routeParams }
}
