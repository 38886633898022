import formatDate from '@/utils/formatDate'
import { addPrefixToComplexPlanningPeriod } from '@/utils/planningPeriod'
import { RESULT_STATUS } from '@/constants'
import { formatProductModel } from '@/components/validations/utils'

export const unusableChoicesListColumns = [
  {
    name: 'planningPeriodRange',
    align: 'left',
    label: 'Planning Period',
    field: ({
      planningPeriodRange:
                { from, to } = {}
    } = {}) => ` ${ addPrefixToComplexPlanningPeriod(from, to) }`,
    sortable: true
  },
  {
    name: 'businessName',
    align: 'left',
    label: 'Product Model',
    field: (
      { productModelType, businessName, userExportsName } = {}
    ) => (
      { productModelType, businessName, userExportsName }
    ),
    format: formatProductModel,
    sortable: true
  },
  {
    name: 'creator',
    label: 'Creator',
    align: 'left',
    field: 'user',
    format: user => (user || 'Automatic'),
    sortable: true
  },
  {
    name: 'createdDate',
    label: 'Uploaded date',
    align: 'left',
    field: 'createdDate',
    format: value => (value ? formatDate(value) : ''),
    sortable: true
  },
  {
    name: 'status',
    label: 'Validation Progress',
    align: 'left',
    field: 'status',
    headerStyle: 'padding-left: .5rem; padding-right: .5rem',
    format: status => RESULT_STATUS[status] || RESULT_STATUS.UNKNOWN,
    sortable: true
  }
]

export const unusableChoicesValidationsColumns = [
  {
    name: 'option',
    align: 'left',
    label: 'Option',
    field: 'option',
    sortable: false
  },
  {
    name: 'choice',
    align: 'left',
    label: 'Unusable Choice',
    field: 'choice',
    sortable: false
  },
  {
    name: 'planningPeriodRange',
    align: 'left',
    label: 'Planning Period',
    field: ({ rangeFrom, rangeTo } = {}) => (
      `${ addPrefixToComplexPlanningPeriod(rangeFrom, rangeTo) }`
    ),
    sortable: false,
    style: 'width: 15%;'
  }
]
