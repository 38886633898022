<template>
  <div>
    <mi-table
      ref="miTable"
      :columns="table.columns"
      :loading="table.loading"
      :rows="table.rows"
      :table-colspan="table.columns.length"
      class="unusable-choices-table q-px-lg"
      column-sort-order="da"
      loading-label="Loading unusable choices. Please wait ..."
      no-data-label="No unusable choices found"
      row-key="id"
      title="Unusable Choices"
      virtual-scroll-sticky-size-start="40"
      hide-pagination
      sticky-header
      striped
      virtual-scroll
      wrap-cells
    >
      <!-- Status column -->
      <template #body-cell-status="{ value }">
        <!-- Validation status -->
        <mi-td class="unusable-choices-table__status-cell">
          <div class="flex items-center">
            <mi-badge class="text-uppercase q-mr-xs" :color="value.color">
              {{ value.title }}
            </mi-badge>
          </div>
        </mi-td>
      </template>
      <template #top-left>
        <div class="row">
          <mi-text-field
            :model-value="searchTerm"
            class="q-mr-auto col-4"
            placeholder="Search for a choice or option"
            debounce="300"
            clearable
            hide-bottom-space
            outlined
            @update:model-value="handleSearchTermChange"
          >
            <template #prepend>
              <mi-icon name="search"></mi-icon>
            </template>
          </mi-text-field>
        </div>
      </template>
    </mi-table>
  </div>
  <div class="pagination q-lg flex flex-center">
    <mi-pagination
      v-if="!!table.rows.length"
      :model-value="pagination.currentPage"
      :max="pagination.totalPages"
      :max-pages="pagination.maxNavPage"
      size="14px"
      boundary-links
      direction-links
      boundary-numbers
      ellipses
      @update:model-value="handlePaginationChange"
    ></mi-pagination>
  </div>
</template>

<script>
  import { onMounted } from 'vue'

  export default {
    name: 'UnusableChoicesValidationsTable',
    props: {
      table: {
        type: Object,
        required: true
      },
      pagination: {
        type: Object,
        required: true
      },
      validationId: {
        type: String,
        required: true
      },
      searchTerm: {
        type: String,
        required: false,
        default: ''
      },
      fetchUnusableChoicesList: {
        type: Function,
        required: false,
        default: () => {}
      },
      handleSearchTermChange: {
        type: Function,
        required: false,
        default: () => {}
      },
      handlePaginationChange: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    setup(props) {
      onMounted(async () => {
        await props.fetchUnusableChoicesList()
      })
    }
  }
</script>

<style lang="scss" scoped>
  .unusable-choices-table {
    min-height: 500px;
    max-height: 80vh;

    .q-table__title {
      font-family: $mi-typography-font-family-condensed;
    }
  }

  .unusable-choices-table-validations-opened {
    height: 300px;
  }

  .unusable-choices-table .unusable-choices-table__status-cell {
    padding-left: 8px;
    padding-right: 8px;
  }

  .unusable-choices-table__header-counter {
    color: #5b6f85;
    font-size: 12px;
    font-style: normal;
    font-family: $mi-typography-font-family-condensed;
    font-weight: 700;
    line-height: 130%;
  }

  .header-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  ::v-deep(.q-table-control) {
    width: 100%;
  }

  .header-content {
    width: 35%;
  }

  .pagination {
    padding: 8px 0 16px;
  }
</style>
