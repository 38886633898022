<template>
  <div class="summary-details-label__content">
    <label class="text-uppercase col-6">{{ title }}</label>
    <p
      class="text-body3 text-weight-regular no-wrap q-overflow-auto"
    >
      {{ text || 'None' }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'SummaryDetailsLabel',
    props: {
      title: {
        type: String,
        required: false,
        default: ''
      },
      text: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .summary-details-label {
    &__content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      label {
        width: 50%;
        margin: 0 0 3%;
        font-size: 12px;
        text-align: left;
        justify-content: center;
        align-items: center;
      }

      label:first-child {
        margin-right: 5%;
      }

      p {
        overflow-wrap: anywhere;
      }
    }
  }
</style>
