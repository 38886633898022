<template>
  <div v-if="!!mappedSummaryData && totalUnusableChoices" class="summary-details-validations">
    <span class="text-h1 text-weight-bold text-accent">{{ totalUnusableChoices }}</span>
    <p class="text-h6 text-weight-bold text-uppercase q-mb-sm">Unusable Choices</p>
    <div class="summary-details-validations__details--separator"></div>
    <mi-btn
      class="text-weight-bold text-center full-width q-pa-sm"
      icon-right="download"
      icon-type
      icon-right-size="16px"
      size="16px"
      dense
      flat
      :loading="isExportLoading"
      @click="onExportClick"
    >
      Export to excel
    </mi-btn>
    <div class="full-height">
      <div class="summary-details-validations__details--separator"></div>
      <label class="summary-details-validations__title">Details</label>
      <div class="summary-details-validations__details">
        <template v-for="(value, key) in mappedSummaryData.firstSection" :key="key">
          <summary-details-label :title="key" :text="value">
          </summary-details-label>
        </template>
        <div class="summary-details-validations__details--separator"></div>
        <template v-for="(value, key) in mappedSummaryData.secondSection" :key="key">
          <summary-details-label :title="key" :text="value">
          </summary-details-label>
        </template>
        <div class="summary-details-validations__details--separator"></div>
        <template v-for="(value, key) in mappedSummaryData.thirdSection" :key="key">
          <summary-details-label :title="key" :text="value">
          </summary-details-label>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import formatDate from '@/utils/formatDate'
  import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'
  import SummaryDetailsLabel from '@/components/validations/test-cases/unusable-choices/summary/SummaryDetailsLabel.vue'

  export default {
    name: 'UnusableChoicesSummaryDetailsValidations',
    components: { SummaryDetailsLabel },
    props: {
      totalUnusableChoices: {
        type: Number,
        required: false,
        default: 0
      },
      summaryData: {
        type: Object,
        required: false,
        default: () => {}
      },
      isExportLoading: {
        type: Boolean,
        required: false,
        default: false
      },
      onExportClick: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    setup(props) {
      const formatRangePP = (from, to) => `${
        removePrefixFromPlanningPeriod(from) } - ${ removePrefixFromPlanningPeriod(to)
      }`

      const mappedSummaryData = computed(() => {
        if (!Object.keys(props.summaryData).length) return null
        return {
          firstSection: {
            'validation id': props.summaryData.id,
            creator: props.summaryData.user,
            'export name': props.summaryData.userExportsName,
            'export creator': props.summaryData.onDemandExportMetadata?.creator,
            'validation created': props.summaryData.createdDate && formatDate(props.summaryData.createdDate)
          },
          secondSection: {
            'product model': props.summaryData.businessName || props.summaryData.userExportsName,
            'option set': props.summaryData.onDemandExportMetadata?.optionSet,
            'planning period': props.summaryData.planningPeriodRange && formatRangePP(
              props.summaryData.planningPeriodRange.from, props.summaryData.planningPeriodRange.to
            )
          },
          thirdSection: {
            'validation finished': props.summaryData.endDateTime
              ? formatDate(props.summaryData.endDateTime) : '',
            'validation status': props.summaryData.status,
            'combination rules': props.summaryData.onDemandExportMetadata?.containsBasicRules
              ? 'Yes' : 'No',
            'tar status': props.summaryData.onDemandExportMetadata?.tarStateConfig
          }
        }
      })

      return { formatDate, formatRangePP, mappedSummaryData }
    }
  }
</script>

<style lang="scss" scoped>
  .summary-details-validations {
    width: 100%;
    height: 100%;
    padding: 0 16px;
    color: $mi-anthracite-800;
    font-family: $mi-typography-font-family-condensed;
    font-weight: 700;

    &__title {
      font-size: 16px;
    }

    &__details {
      margin-top: 24px;
      margin-bottom: 48px;

      &--separator {
        width: 100%;
        margin: 8px 0;
        height: 0;
        border: 1px solid $mi-anthracite-50;
        display: inline-block;
      }

      &--separator:last-child {
        display: none;
      }
    }
  }
</style>
