<template>
  <div class="summary-control">
    <div class="unusable-choices-summary-validations">
      <div class="unusable-choices-summary-validations__header">
        <h3 class="summary-title">Summary</h3>
      </div>
      <div class="unusable-choices-summary-validations__details">
        <unusable-choices-summary-details-validations
          :summary-data="summaryData.value"
          :total-unusable-choices="totalElements"
          :is-export-loading="isExportLoading"
          :on-export-click="onExportClick"
        >
        </unusable-choices-summary-details-validations>
      </div>
    </div>
  </div>
</template>

<script>
  import UnusableChoicesSummaryDetailsValidations
    from '@/components/validations/test-cases/unusable-choices/summary/SummaryDetails.vue'

  export default {
    name: 'UnusableChoicesSummaryValidations',
    components: { UnusableChoicesSummaryDetailsValidations },
    props: {
      summaryData: {
        type: Object,
        required: true
      },
      totalElements: {
        type: Number,
        required: false,
        default: 0
      },
      isExportLoading: {
        type: Boolean,
        required: false,
        default: false
      },
      onExportClick: {
        type: Function,
        required: false,
        default: () => {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .summary-control {
    border-left: .15rem solid $mi-anthracite-50;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }

  .unusable-choices-summary-validations {
    &__header {
      padding: 16px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      border-bottom: .15rem solid $mi-anthracite-50;

      .summary-title,
      .summary-collapse {
        margin: 0;
      }

      .summary-collapse__img {
        width: 18px;
      }
    }
  }
</style>
